import React, { useEffect, useMemo, useRef, useState } from "react";
import {
    useTable,
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useRowSelect,
} from "react-table";
import Select from "react-select";
import loaderImage from "../../../assets/loader-sm.gif";
import useAxios from "../../../hooks/useAxios";
import { PREFIX_ARRAY } from "../../../constants";
import NestedTable from "./NestedTable";

const DataGrid = ({ columns, data, pageIndex, pageSize, totalRecord, onPageChange, onPageSizeChange, onSelectionChange, onSearchListChange, searchFormRecords, gridRefresh, highlight, setHighlight, viewDetails, loader,gridOptions,formId, setGridColumns,gridColumns}: any) => {
   //console.log(columns)
    const [searchList, setSearchList]: any = useState({});
    const [searchJson, setSearchJson]: any = useState({});
    const [selectedIds, setSelectedIds]:any = useState([]);
    const [thPosition, setThPosition]:any = useState({});
    const [visibleDynamicPanel, setVisibleDynamicPanel]:any = useState({});
    const gridContainerRef:any = useRef(null);
    const [dynamicOptionsMap, setDynamicOptionsMap] = useState<{ [key: string]: any[] }>({});
    const axios = useAxios();
    
    const actionColumn = {
        Header: () => <span className="grid-col-freeze">Action</span>,
        accessor: 'action',
        id: 'action',
        Cell: ({ row }: any) => (
            <div className="eyeicon tooltip-container" onClick={() => viewDetails(row.original)}>
                <i className="fa fa-eye"></i>
            </div>
        ),
        disableFilters: true,
        disableSortBy: true,
        width: 115,
    };
    const modifiedColumns = useMemo(() => gridOptions.actionColumn !==false ?  [...columns, actionColumn] : columns, [
        columns,
    ]);

    // Fetch options when columns change
    useEffect(() => {
        const fetchOptionsForColumn = async (column: any) => {
            if (column && column.choicesByUrl && column.choicesByUrl?.url) {
                try {
                    const response = await fetch(column.choicesByUrl.url);
                    const data = await response.json();
                    const fetchedOptions = data.data.map((item: any) => ({
                        value: item.Value,
                        label: item.Value,
                    }));
                    setDynamicOptionsMap((prev) => ({
                        ...prev,
                        [column.accessor]: fetchedOptions,
                    }));
                } catch (error) {
                    console.error('Error fetching options for column:', column.id, error);
                }
            } else {
                const staticOptions = column.choices?.map((choice: any) => {
                    const choiceValue = typeof choice.Key === 'object' ? choice.Key.value : choice.Key;
                    const choiceKey = typeof choice.Value === 'object' ? choice.Value.text : choice.Value;
                
                    return {
                        value: choiceValue,  
                        label: choiceValue, 
                    };
                }) || [];
                setDynamicOptionsMap((prev) => ({
                    ...prev,
                    [column.accessor]: staticOptions,
                }));
            }
        };
        columns.forEach((column: any) => {
            if (column.type === "dropdown" || column.type === "tagbox") {
                fetchOptionsForColumn(column);
            }
        });
    }, [columns]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        selectedFlatRows,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex: tablePageIndex, pageSize: tablePageSize },
        setFilter,
    } = useTable(
        {
            columns: modifiedColumns,
            data,
            initialState: { pageIndex, pageSize },
            manualPagination: true,
            pageCount: Math.ceil(totalRecord / pageSize),
        },
        useFilters,
        useSortBy,
        usePagination,
        useFlexLayout,
        useResizeColumns,
        useRowSelect,
        (hooks: any) => {
            hooks.visibleColumns.push((columns: any) => [
                ...(gridOptions.rowSelection !== false
                    ? [
                          {
                              id: "selection",
                              Header: ({ getToggleAllPageRowsSelectedProps }: any) => {
                                  const allPageRowsSelectedProps = getToggleAllPageRowsSelectedProps();
                                  return (
                                      <div className="checkbox-container">
                                          <input title="" type="checkbox" {...allPageRowsSelectedProps} indeterminate={allPageRowsSelectedProps.indeterminate ? true : undefined} className="custom-checkbox"/>
                                      </div>
                                  );
                              },
                              Cell: ({ row }: any) => {
                                  const rowSelectedProps = row.getToggleRowSelectedProps();
                                  return (
                                      <div className="checkbox-container">
                                          <input title="" type="checkbox" {...rowSelectedProps} indeterminate={rowSelectedProps.indeterminate ? true : undefined} className="custom-checkbox"/>
                                      </div>
                                  );
                              },
                              disableSortBy: true,
                              disableFilters: true,
                              width: 50,
                          },
                      ]
                    : []),
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        onSelectionChange(selectedFlatRows.map((row: any) => row.original));
    }, [selectedFlatRows]);

    useEffect(() => {
        setSearchJson({});
        setSearchList((prevList: any) => {
            const updatedList = { ...prevList };
            selectedIds.forEach((id: any) => {
                if(id === "CreatedAt"){
                    updatedList[id].from = "";
                    updatedList[id].to = "";
                }
                updatedList[id] = "";
            });
            return updatedList;
        });
        setSelectedIds([]);
        setHighlight(false);
        const scrollContainer = gridContainerRef.current;
        if (scrollContainer) {
            // Use requestAnimationFrame to ensure that the DOM element is fully rendered before accessing it
            requestAnimationFrame(() => {
                scrollContainer.scrollLeft = 0;
            });
        }
        setVisibleDynamicPanel({});
    }, [gridRefresh]);

    const onDateChange = (field: any, key: string, event: any) => {
        
        if (field && event.target.value) {
            setSearchList((prevList: any) => ({
                ...prevList,
                [field]: {
                    ...prevList[field],
                    [key]: event.target.value
                }
            }));
            setSearchJson((prevList: any) => ({
                ...prevList,
                [field]: {
                    ...prevList[field],
                    [key]: event.target.value
                }
            }));
            setSelectedIds((prevIds: any[]) => {
                if (!prevIds.includes(field)) {
                    return [...prevIds, field];
                }
                return prevIds;
            });
        }
    };

    const onChangeFilterForSelect = (field: any, value: any, col?:any) => {
        if (field) {
            if (Array.isArray(value) && value.length === 0) {
                setSearchJson((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSearchList((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSelectedIds((prevIds: any[]) => prevIds.filter(id => id !== field));
            } else if (value === null || typeof value === "undefined") {
                setSearchJson((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSearchList((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
                setSelectedIds((prevIds: any[]) => prevIds.filter(id => id !== field));
            } else if (Array.isArray(value)) {
                if((col.type === "tagbox" || col.type === "checkbox") && !col?.choicesByUrl?.url){
                    const upValue = value.map((item: any) => {
                        const foundChoice = col.choices?.find((choice: any) => choice.Key === item);
                        return foundChoice ? foundChoice.Value ?? foundChoice.Key : item; 
                    });
                    setSearchJson((prevList: any) => ({
                        ...prevList,
                        [field]: upValue,
                    }));
                    setSearchList((prevList: any) => ({
                        ...prevList,
                        [field]: value,
                    }));
                    setSelectedIds((prevIds: any[]) => {
                        if (!prevIds.includes(field)) {
                            return [...prevIds, field];
                        }
                        return prevIds;
                    });  
                }else{
                    setSearchJson((prevList: any) => ({
                        ...prevList,
                        [field]: value,
                    }));
                    setSearchList((prevList: any) => ({
                        ...prevList,
                        [field]: value,
                    }));
                    setSelectedIds((prevIds: any[]) => {
                        if (!prevIds.includes(field)) {
                            return [...prevIds, field];
                        }
                        return prevIds;
                    });
                }
            } else if (typeof value === "object") {
                let obj: any = {};
                let newValue:any;
                if((col.type === "dropdown") && !col?.choicesByUrl?.url){
                    const foundChoice = col.choices?.find((choice: any) => choice.Key === value.label);
                    newValue = {
                        Key:foundChoice ? foundChoice.Key: foundChoice.Value,
                        Value:foundChoice ? foundChoice.Value: foundChoice.Key,
                    }
                }
                obj["Key"] = value.label;
                obj["Value"] = value.value;
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: newValue ? newValue : obj,
                }));
                setSearchList((prevList: any) => ({
                    ...prevList,
                    [field]: obj
                }));
                setSelectedIds((prevIds: any[]) => {
                    if (!prevIds.includes(field)) {
                        return [...prevIds, field];
                    }
                    return prevIds;
                });
            } else {
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
                setSearchList((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
                setSelectedIds((prevIds: any[]) => {
                    if (!prevIds.includes(field)) {
                        return [...prevIds, field];
                    }
                    return prevIds;
                });
            }
        }
    }

    // const onChangeFilter = (field: any, value: any, col?: any) => {
    //     if (col.type === "radiogroup") {
    //         let newValue: any;
    //         const foundChoice = col.choices?.find((choice: any) => choice.Key === value);
    //         if (foundChoice?.Value !== value) {
    //             newValue = foundChoice?.Value;
    //         }
    //         else {
    //             newValue = foundChoice?.Key;
    //         }
    //         setSearchJson((prevList: any) => ({
    //             ...prevList,
    //             [field]: newValue,
    //         }));
    //         setSearchList((prevList: any) => ({
    //             ...prevList,
    //             [field]: value,
    //         }));
    //         setSelectedIds((prevIds: any[]) => {
    //             if (!prevIds.includes(field)) {
    //                 return [...prevIds, field];
    //             }
    //             return prevIds;
    //         });
    //     } else if(col.type === "fullname"){
    //         const searchListValue = value;
    //         if(col.prefixChoices){
    //             let prefix = value && value.trim().split(" ")[0];
    //             const option = col.prefixChoices.find((opt:any) => opt.text === prefix);
    //             prefix = option ? option.value : prefix;
    //             const words = value && value.trim().split(" ");
    //             words ? words[0] = prefix : "";
    //             value = words && words.join(" ")
    //         }
    //         setSearchList((prevList: any) => ({
    //             ...prevList,
    //             [field]: searchListValue,
    //         }));
    //         setSelectedIds((prevIds: any[]) => {
    //             if (!prevIds.includes(field)) {
    //                 return [...prevIds, field];
    //             }
    //             return prevIds;
    //         });
    //         setSearchJson((prevList: any) => ({
    //             ...prevList,
    //             [field]: value,
    //         }));
    //     } else {
    //         setSearchList((prevList: any) => ({
    //             ...prevList,
    //             [field]: value,
    //         }));
    //         setSelectedIds((prevIds: any[]) => {
    //             if (!prevIds.includes(field)) {
    //                 return [...prevIds, field];
    //             }
    //             return prevIds;
    //         });
    //         setSearchJson((prevList: any) => ({
    //             ...prevList,
    //             [field]: value,
    //         }));
    //     }
    // };
    
    const onChangeFilter = (field: any, value: any, col?: any) => {
        let newValue = value;
    
        if (col?.type === "radiogroup") {
            const foundChoice = col.choices && col.choices?.find((choice: any) => choice.Key === value);
            newValue = foundChoice?.Value !== value ? foundChoice?.Value : foundChoice?.Key;
        } else if (col?.type === "fullname") {
            const prefix = value && value.trim().split(" ")[0];
            const prefixOptions = col.prefixChoices ? col.prefixChoices : PREFIX_ARRAY; 
            const option = prefixOptions.find((opt:any) => opt.text === prefix);
            if (option) {
                const words = value.trim().split(" ");
                words[0] = option.value;
                newValue = words.join(" ");
            }
        }
    
        setSearchList((prevList: any) => ({
            ...prevList,
            [field]: value,
        }));
    
        setSelectedIds((prevIds: any[]) => 
            !prevIds.includes(field) ? [...prevIds, field] : prevIds
        );
    
        setSearchJson((prevList: any) => ({
            ...prevList,
            [field]: newValue,
        }));
    };

    const handleKeyDown = (event: any, field?:any, value?:any, col?:any) => {
        // if(col.type === "radiogroup"){
        //     const foundChoice = col.choices?.find((choice: any) => choice.Key === value);
        //     if(foundChoice?.Value !== value) value = foundChoice?.Value;
        //     else value = foundChoice?.Key;
        //     setSearchJson((prevList: any) => ({
        //         ...prevList,
        //         [field]: value,
        //     })); 
        // }
        if (event.key === "Enter") {
            searchFormRecords();
            setHighlight(true);
        }
    };

    const customStyles = {
        menuList: (provided:any) => ({
          ...provided,
          overflowX: 'auto', // Enable horizontal scrolling
        }),
        option: (provided:any) => ({
          ...provided,
          whiteSpace: 'nowrap', // Prevent text from wrapping
        }),
    };

    useEffect(() => {
        onSearchListChange(searchJson);
    }, [searchJson]);

    const renderFilter = (column: any) => {
        const { id, type, choices, inputType } = column;
        if (id === "tp_serialNumber" || type =="paneldynamic" || type =="panelDynamic") return null;
        const options = dynamicOptionsMap[id] || [];
        if (inputType === "date" || inputType === "datetime-local") {
            return (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="date" className="inputtextd1" value={searchList[id]?.from || ""} onChange={(event) => onDateChange(id, "from", event)} />
                    <span style={{ margin: "0 5px" }}>-</span>
                    <input type="date" className="inputtextd1" value={searchList[id]?.to || ""} onChange={(event) => onDateChange(id, "to", event)} />
                </div>
            )
        }

        switch (type) {
            case "checkbox":
                return (
                    <Select
                    styles={customStyles}
                    isMulti
                    value={Array.isArray(searchList[id]) ? searchList[id].map((item: string) => ({ value: item, label: item })) : []}
                    onChange={(value: any) => onChangeFilterForSelect(id, value.map((v: any) => v.label), column)}
                    options={choices.map((choice: any) => ({ value: choice.Value, label: choice.Key }))}
                />
                );
                case "tagbox":
                return (
                    <Select
                        isMulti
                        styles={customStyles}
                        value={
                            Array.isArray(searchList[id]) && searchList[id].length
                                ? searchList[id].map((item: string) => ({ value: item, label: item }))
                                : []
                        }
                        onChange={(value: any) => onChangeFilterForSelect(id, value.map((v: any) => v.value), column)}
                        options={options}
                    />
                );
                case "dropdown":
                    return (
                        <Select
                        styles={customStyles}
                        closeMenuOnSelect={false}
                            
                            value={
                                searchList[id] && searchList[id].Key && searchList[id].Value
                                    ? { value: searchList[id].Key, label: searchList[id].Value }
                                    : null
                            }
                            onChange={(selectedOption: any) => onChangeFilterForSelect(id, selectedOption, column)}
                           options={options}
                        />
                    );
            case "CreatedAt":
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input type="date" className="inputtextd1" value={searchList[id]?.from || ""} onChange={(event) => onDateChange(id, "from", event)} />
                        <span style={{ margin: "0 5px" }}>-</span>
                        <input type="date" className="inputtextd1" value={searchList[id]?.to || ""} onChange={(event) => onDateChange(id, "to", event)} />
                    </div>
                );
            case "datepicker":
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input type="date" className="inputtextd1" value={searchList[id]?.from || ""} onChange={(event) => onDateChange(id, "from", event)} />
                        <span style={{ margin: "0 5px" }}>-</span>
                        <input type="date" className="inputtextd1" value={searchList[id]?.to || ""} onChange={(event) => onDateChange(id, "to", event)} />
                    </div>
                );
            case "privacypolicy":
            case "termsconditions":
                const selectedOption = searchList[id] === true ? { value: true, label: "Yes" } : searchList[id] === false ? { value: false, label: "No" } : null;
                return (
                    <Select
                        isClearable
                        value={selectedOption}
                        onChange={(value: any) => onChangeFilterForSelect(id, value ? value.value : null)}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' }
                        ]}
                    />
                );
            case "boolean":
                const selectedOptionForBoolean = searchList[id] === true ? { value: true, label: column.labelTrue ? column.labelTrue : "Yes" } : searchList[id] === false ? { value: false, label: column.labelFalse ? column.labelFalse : "No" } : null;
                return (
                    <Select
                        isClearable
                        value={selectedOptionForBoolean}
                        onChange={(value: any) => onChangeFilterForSelect(id, value ? value.value : null)}
                        options={[
                            { value: true, label: column.labelTrue ? column.labelTrue : "Yes" },
                            { value: false, label: column.labelFalse ? column.labelFalse : "No" },
                        ]}
                    />
                );
            case "file":
                const selectedOption1 = searchList[id] === true ? { value: true, label: 'Yes' } : searchList[id] === false ? { value: false, label: 'No' } : null;
                return (
                    <Select
                        isClearable
                        onChange={(value: any) => onChangeFilterForSelect(id, value ? value.value : null)}
                        value={selectedOption1}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' }
                        ]}
                    />
                );
            default:
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input className="inputtext" onChange={(e) => onChangeFilter(id, e.target.value || undefined, column)} value={searchList[id] || ""} onKeyDown={handleKeyDown} />
                    </div>
                )
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = 5;

        if (pageCount <= totalPageNumbers) {
            for (let i = 0; i < pageCount; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => {
                            gotoPage(i);
                            onPageChange(i);
                        }}
                        className={tablePageIndex === i ? "active" : ""}
                    >
                        {i + 1}
                    </button>
                );
            }
        } else {
            let startPage = Math.max(0, tablePageIndex - Math.floor(totalPageNumbers / 2));
            let endPage = startPage + totalPageNumbers - 1;

            if (endPage >= pageCount) {
                endPage = pageCount - 1;
                startPage = endPage - totalPageNumbers + 1;
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => {
                            gotoPage(i);
                            onPageChange(i);
                        }}
                        className={tablePageIndex === i ? "active" : ""}
                    >
                        {i + 1}
                    </button>
                );
            }

            if (startPage > 0) {
                pageNumbers.unshift(<span key="ellipsis1">...</span>);
                pageNumbers.unshift(
                    <button
                        key={0}
                        onClick={() => {
                            gotoPage(0);
                            onPageChange(0);
                        }}
                        className={tablePageIndex === 0 ? "active" : ""}
                    >
                        1
                    </button>
                );
            }

            if (endPage < pageCount - 1) {
                pageNumbers.push(<span key="ellipsis2">...</span>);
                pageNumbers.push(
                    <button
                        key={pageCount - 1}
                        onClick={() => {
                            gotoPage(pageCount - 1);
                            onPageChange(pageCount - 1);
                        }}
                        className={tablePageIndex === pageCount - 1 ? "active" : ""}
                    >
                        {pageCount}
                    </button>
                );
            }
        }

        return pageNumbers;
    };

    const highlightText = (cell: any) => {
        let searchStr = searchJson[cell.column.id];
        if (cell.column.type === "datepicker" || cell.column.inputType === "date" || cell.column.inputType === "datetime-local" || cell.column.id === "CreatedAt") return cell.render("Cell");
        if (cell.column.type === "file" && searchStr) {
            const value = cell.value ? "Yes" : "No";
            searchStr = searchStr ? "Yes" : "No";
            const regex = new RegExp(`(${searchStr})`, 'gi');
            const parts = value.toString().split(regex);
            const data = parts.map((part: any, index: any) => part === searchStr ?
                (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                : (part));
            return data
        }
        if (searchStr) {
            if (Array.isArray(cell.value) && cell.column.type != "file") {
                let newValue:any ="";
                if((cell.column.type === "tagbox" || cell.column.type === "checkbox") && !cell?.column?.choicesByUrl?.url){
                    newValue = cell.value.map((item: any) => {
                        const foundChoice = cell.column.choices?.find((choice: any) => choice.Value === item);
                        return foundChoice ? foundChoice.Key ?? foundChoice.Value : item;
                    })
                    const newsearchStr = searchStr.map((item: any) => {
                        const foundChoice = cell.column.choices?.find((choice: any) => choice.Value === item);
                        return foundChoice ? foundChoice.Key ?? foundChoice.Value : item;
                    })
                    searchStr = newsearchStr
                }else{
                    newValue = cell.value
                }
                return newValue.map((item: any, idx: number) => {
                    // Create a regex that matches any of the strings in searchStr
                    if(Array.isArray(searchStr))  var  regex = new RegExp(`(${searchStr.join("|")})`, "gi"); // Highlight all search strings
                    else  var regex = new RegExp(`(${searchStr})`, "gi"); //

                    // Split each item into parts based on the regex match
                    const parts = item.toString().split(regex);

                    return (
                        <span key={idx}>
                            {parts.map((part: string, index: number) => (
                                <>
                                    {part.match(regex) ? (
                                        <span key={index} style={{ backgroundColor: "#fc0" }}>
                                            {part}
                                        </span>
                                    ) : (
                                        part
                                    )}
                                    {/* Add a comma after each part except the last one */}
                                    {index < parts.length - 1 && <span key={`comma-${index}`}>, </span>}
                                </>
                            ))}
                        </span>
                    );
                });
            } else if (typeof cell.value === "object") {
                if (cell.column.type === "fullname") {
                    let { prefixDropdown, prefixText, firstName, middleName, lastName } = cell.value;
                    const prefixOptions = cell.column.prefixChoices ? cell.column.prefixChoices : PREFIX_ARRAY; 
                    const option = prefixOptions.find((opt:any) => opt.value === prefixDropdown);
                    if(option){
                        prefixDropdown = option.text;
                        const words = searchStr && searchStr.trim().split(" ");
                        words[0] = prefixDropdown;
                        searchStr = words.join(" ")
                    }
                    const fullname = (prefixDropdown || "") + " " + (prefixText || "") + " " + (firstName || "") + " " + (middleName || "") + " " + (lastName || "");
                    const searchWords = searchStr.trim().split(/\s+/);
                    const regex = new RegExp(`(${searchWords.join('|')})`, "gi");
                    // const regex = new RegExp(`(${searchStr})`, "gi");
                    const parts = fullname.toString().split(regex);
                    const data = parts.map((part: any, index: any) =>
                        searchWords.some((word:any) => word.toLowerCase() === part.toLowerCase()) ? (
                            <span key={index} style={{ backgroundColor: "#fc0" }}>
                                {" "}
                                {part.toString()}{" "}
                            </span>
                        ) : (
                            part
                        )
                    );
                    return data;
                }
                if (cell.column.type === "address") {
                    if (!['.city', '.country', '.postalCode', '.state'].includes(cell.column.id)) {
                        const { streetAddressLine1, streetAddressLine2 } = cell.value || {};
                        let address = streetAddressLine1 || '';
                        if (streetAddressLine2) {
                            address += `, ${streetAddressLine2}`;
                        }
                        const regex = new RegExp(`(${searchStr})`, 'gi');
                        const parts = address.toString().split(regex);
                        const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                            (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                            : (part));
                        return data
                    }
                }
            } else if (cell.column.type === "privacypolicy" || cell.column.type === "termsconditions") {
                searchStr = searchStr ? "Yes" : "No";
                const regex = new RegExp(`(${searchStr})`, 'gi');
                let value = cell.value ? "Yes" : "No"
                const parts = value.toString().split(regex);
                const data = parts.map((part: any, index: any) =>
                    part === searchStr ? (
                        <span key={index} style={{ backgroundColor: "#fc0" }}>
                            {" "}
                            {part.toString()}{" "}
                        </span>
                    ) : (
                        part
                    )
                );
                return data;
            } else if (cell.column.type === "boolean") {
                searchStr = searchStr ? cell.column.labelTrue ? cell.column.labelTrue : "Yes" : cell.column.labelFalse ? cell.column.labelFalse : "No";
                const regex = new RegExp(`(${searchStr})`, "gi");
                let value = cell.value ? cell.column.labelTrue ? cell.column.labelTrue : "Yes" : cell.column.labelFalse ? cell.column.labelFalse : "No";
                const parts = value.toString().split(regex);
                const data = parts.map((part: any, index: any) =>
                    part === searchStr ? (
                        <span key={index} style={{ backgroundColor: "#fc0" }}>
                            {" "}
                            {part.toString()}{" "}
                        </span>
                    ) : (
                        part
                    )
                );
                return data;
            } else if (cell.column.type === "dropdown" && cell.value) {
                searchStr = searchStr.Value;
                if(!cell?.column?.choicesByUrl?.url){
                    const foundChoice = cell?.column?.choices?.find((choice: any) => choice.Value === searchStr);
                    if(foundChoice?.Key !== searchStr) searchStr = foundChoice?.Key;
                    // else searchStr = foundChoice?.searchStr;
                    cell.value = searchStr;
                }
                const regex = new RegExp(`(${searchStr})`, "gi");
                const parts = cell.value.toString().split(regex);
                const data = parts.map((part: any, index: any) =>
                    part === searchStr ? (
                        <span key={index} style={{ backgroundColor: "#fc0" }}>
                            {" "}
                            {part.toString()}{" "}
                        </span>
                    ) : (
                        part
                    )
                );
                return data;
            } else { 
                if (!searchStr) return cell.value;
                const regex = new RegExp(`(${searchStr})`, "gi");
                if(!cell?.column?.choicesByUrl?.url && cell.column.type === "radiogroup"){
                    const foundChoice = cell?.column?.choices?.find((choice: any) => choice.Value === searchStr);
                    if(foundChoice?.Key !== searchStr) searchStr = foundChoice?.Key;
                    // else searchStr = foundChoice?.Value;
                    cell.value = searchStr;
                }
                if(cell?.column?.monthOptions && cell.column.type === "monthfield"){
                    const option = cell?.column?.monthOptions.find((opt: any) => opt.value === cell.value);
                    cell.value =  option ? option.text : cell.value;
                }
                if (cell.value) {
                    const parts = cell.value.toString().split(regex);
                    const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                        (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                        : (part));
                    return data
                }
            }
        } else {
            return cell.render("Cell");
        }
    };

    const handleColumnResize = (column: any, newWidth: number) => {
        const updatedColumns = gridColumns.map((col:any) => 
            col.field === column.id ? { ...col, width: newWidth } : col
        );
    
        setGridColumns(updatedColumns);
        return updatedColumns // Assuming `setColumns` is your state setter
    };
    
    const handleMouseDown = (e: React.MouseEvent, column: any) => {
        if (column.id === "tp_serialNumber") return;
        e.preventDefault();
        let cols:any;
        let startX = e.clientX;
        const columnElement = (e.target as HTMLElement).closest("th");
        if (!columnElement) return;
        const startWidth = columnElement.getBoundingClientRect().width;
    
        const handleMouseMove = (event: MouseEvent) => {
            const newWidth = startWidth + (event.clientX - startX);
            if (newWidth >= 150) {
                cols = handleColumnResize(column, newWidth);
            }
        };
    
        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
            
            setTimeout(async () => {
                await axios.put('common/grid-setting', { PageType: "FormRecords_" + formId, Columns: cols });
            }, 3000)
        };
    
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

      // Remove the `title` attribute from checkboxes after rendering
      useEffect(() => {
        const checkboxes = document.querySelectorAll('.custom-checkbox');
        checkboxes.forEach((checkbox) => {
            checkbox.removeAttribute('title'); // Remove the title attribute
        });
    }, [page]);
    
    function calculateThPosition(table:any, thElement:any) {
        // Check if the table and thElement exist
        if (!table || !thElement) {
            console.error("Table or th element not found!");
            return null;
        }
    
        // Get the bounding rectangles of the table and the th element
        const tableRect = table.getBoundingClientRect();
        const thRect = thElement.getBoundingClientRect();
    
        // Calculate position from the left edge of the table
        const positionFromLeft = thRect.left - tableRect.left;
    
        // Calculate position from the right edge of the table
        const positionFromRight = tableRect.right - thRect.right;
    
        // Return the results as an object
        return {
            positionFromLeft: positionFromLeft,
            positionFromRight: positionFromRight
        };
    }
    const handleThClick = (event:any) => {
        // event.target gives you the element that was clicked
        const clickedElement = event.target;
    
        // You can access properties of the clicked element
        // console.log('Clicked element:', clickedElement);
        // console.log('Element text:', clickedElement.textContent);
        // console.log('Element ID:', clickedElement.id);

        const table = document.querySelector('.table-bordered'); // Select the table
       // const thElement = document.querySelector('th'); // Select the specific <th> element
        const thPosition = calculateThPosition(table, clickedElement); // Call the function
        //console.log(thPosition);
        setThPosition(thPosition)
    };
    // Example usage:
    const showDynamicPanel = (e: any, fieldName: any, dpIndex: number) => {
        // Find the closest <tr> element
        const closestTr = e.target.closest('tr');
        const closestTd = e.target.closest('td');
      
        // If the closest <tr> is already active, deactivate it and return
        if (closestTr.classList.contains('dpactive')) {
          closestTr.classList.remove('dpactive');
          const icon = closestTd.querySelector('i');
          if (icon) {
            icon.classList.remove('fa-angle-up');
            icon.classList.add('fa-angle-down');
          }
          setVisibleDynamicPanel({}); // Reset the visible dynamic panel
          return;
        }
      
        // Remove the 'dpactive' class and reset icons from all rows
        document.querySelectorAll('tr').forEach((row) => {
          row.classList.remove('dpactive');
          const icon = row.querySelector('i');
          if (icon) {
            icon.classList.remove('fa-angle-up');
            icon.classList.add('fa-angle-down');
          }
        });
      
        // Add the 'dpactive' class and update the icon for the closest <tr>
        if (closestTr) {
          closestTr.classList.add('dpactive');
          const icon = closestTd.querySelector('i');
          if (icon) {
            icon.classList.remove('fa-angle-down');
            icon.classList.add('fa-angle-up');
          }
        }
      
        // Set the visible dynamic panel
        setVisibleDynamicPanel({ field: fieldName, dpIndex: dpIndex });

        
        const tableContainer:any = document.querySelector('.table-responsive');
        tableContainer.scrollTo({
          left: 0,
          behavior: 'smooth', // Smooth scrolling
        });
          
      };
      const getFilteredTemplateElements = (templateElements:any) => {
        let ignorType = ["multilookupfield","lookupfield","file","panelDynamic","address", "image", "customlabel","paneldynamic"];
        return templateElements.filter((te:any)=>!ignorType.includes(te.type) && te.inputType !="password");
      };
    
   
    return (
        <>
            <div ref={gridContainerRef} className="table-responsive grid-table-container border_table">
                <table {...getTableProps()} className="table table-bordered">
                    <thead>
                        {headerGroups.map((headerGroup: any, headerGroupIndex: number) => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <React.Fragment key={`headerGroup-${headerGroupIndex}`}>
                                    <tr {...headerGroupProps} className="head-row title">
                                        {headerGroup.headers.map((column: any, columnIndex: number) => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps();
                                            return (
                                                <th onClick={handleThClick} key={`header-${headerGroupIndex}-${columnIndex}`} {...columnProps} className="header-name">
                                                    <div className="header-content">
                                                        <span className="header-text">{column.render("Header")}</span>
                                                        {!column.disableSortBy && (
                                                            <i
                                                                className={`myIcon fas fa-sort ${column.isSorted ? (column.isSortedDesc ? "sort-desc" : "sort-asc") : ""}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    column.toggleSortBy(!column.isSortedDesc);
                                                                }}
                                                            ></i>
                                                        )}
                                                    </div>
                                                    {/* <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? "isResizing" : ""}`} onMouseDown={(e) => handleMouseDown(e, column)}/> */}
                                                    {!["tp_serialNumber", "selection"].includes(column.id) && (
                                                        <div
                                                            {...column.getResizerProps()}
                                                            className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                                                            onMouseDown={(e) => handleMouseDown(e, column)}
                                                        >
                                                        <div className="grip"></div>
                                                        </div>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    {gridOptions.searchColumn !== false &&   <tr key={`filterRow-${headerGroupIndex}`} {...headerGroupProps} className="head-row">
                                        {headerGroup.headers.map((column: any, columnIndex: number) => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps();
                                            return (
                                                <th
                                                    key={`filter-${headerGroupIndex}-${columnIndex}`}
                                                    {...columnProps}
                                                    className="search-input">
                                                    {column.canFilter ? renderFilter(column) : null}
                                                </th>
                                            );
                                        })}
                                    </tr> }
                                </React.Fragment>
                            );
                        })}
                    </thead>
                    {loader && (
                        <div className="loader-overlay">
                            <img src={loaderImage} alt="Loading..." />
                            <span className="no-records-message">No record found</span>
                        </div>
                    )}
                    {!loader && (
                        <tbody {...getTableBodyProps()}>
                            {page.map((row: any, rowIndex: number) => {
                                prepareRow(row);
                                const { key, ...rowProps } = row.getRowProps();
                                let dynmicPanelCoumns:any =[];
                                return (
                                    <React.Fragment key={`headerGroup-${rowIndex}`}>
                                    <tr key={`row-${row.id}`} {...rowProps} className="hover-row">
                                        {row.cells.map((cell: any, cellIndex: number) => {
                                            const { key: cellKey, ...cellProps } = cell.getCellProps();
                                            //const cellValue = cell.render("Cell");
                                            return (
                                                <td key={`cell-${row.id}-${cellIndex}`} {...cellProps} className="input-with-ellipsis">
                                                    {/* {highlightContent ? <span>{cell.render("Cell")}</span> : cell.render("Cell")} */}
                                                    {/* {highlightContent ? highlightText(cell) : cell.render(z"Cell")} */}
                                                    {cell.column.type === "paneldynamic" ? (
                                                        (dynmicPanelCoumns.push(cell),
                                                        cell.value ? (
                                                            <div 
                                                                className="no-of-dprecords" 
                                                                onClick={(event) => showDynamicPanel(event, cell.column.id, rowIndex)}
                                                            >
                                                               <div> {cell.value.length} Records <i className="fa fa-angle-down"></i></div>
                                                            </div>
                                                        ) : (
                                                            <div onClick={(event) => showDynamicPanel(event, cell.column.id, rowIndex)}>
                                                               <div> 0 Records <i className="fa fa-angle-down"></i></div>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        highlight ? highlightText(cell) : cell.render("Cell")
                                                    )}

                                                </td>
                                            );
                                        })}
                                    </tr>
                                    { dynmicPanelCoumns.length > 0 && visibleDynamicPanel.dpIndex == rowIndex && 
                                        dynmicPanelCoumns
                                            .filter((cell:any) => cell.column.id === visibleDynamicPanel.field)
                                            .map((cell: any, cellIndex: number) => (
                                            <tr key={cellIndex} className="nestedtr">
                                                <td colSpan ={row.cells.length}>
                                                    <NestedTable thPosition={{}} columns={getFilteredTemplateElements(cell.column.templateElements)} data={cell.value} />
                                                </td>
                                            </tr>
                                            ))}
                                      </React.Fragment>
                                );
                            })}
                        </tbody>
                    )}
                </table>
            </div>

            <div className="pagination-container">
                <div className="per-page">
                    <span>
                        Per Page{" "}
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                onPageSizeChange(Number(e.target.value));
                            }}
                        >
                            {[10, 15, 20, 25, 50, 75, 100].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                {totalRecord > pageSize && (
                    <div className="pagination-controls">
                        <button
                            onClick={() => {
                                previousPage();
                                onPageChange(tablePageIndex - 1);
                            }}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        {renderPageNumbers()}
                        <button
                            onClick={() => {
                                nextPage();
                                onPageChange(tablePageIndex + 1);
                            }}
                            disabled={!canNextPage}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default DataGrid;
