import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
const FieldsMap = ({
  field,
  items,
  sectionIndex,
  fieldIndex,
  section,
  handleInputChange,
  handleDeleteField,
  getPreviousSectionIds,
  isFormDisabled,
  handleSliderChange,
  handlePrefillChange,
}: any) => (
  <div className="form-sec">
    <div className="form-field-bx2  form-group">
      {/* <label>Form Field</label> */}
      <Select
        isClearable={true}
        isDisabled={field.IsNew == true ? false : true}
        options={items.concat(getPreviousSectionIds(sectionIndex))}
        value={field.FormFieldName ? { value: field.FormFieldName, label: items.find((f: any) => f.name == field.FormFieldName) ? items.find((f: any) => f.name == field.FormFieldName).label : field.FormFieldName } : ""}
        onChange={(e: any) => handleInputChange(sectionIndex, fieldIndex, 'FormFieldName', e ? e.value : "")}
      />
      {section.validationMessages.FieldsMap[fieldIndex].FormFieldName && (
        <div className="error">{section.validationMessages.FieldsMap[fieldIndex].FormFieldName}</div>
      )}
    </div>
    <div className="Optimiser-field-bx2 form-group">
      {/* <label>Optimiser Field</label> */}
      <Select
        isClearable={true}
        isDisabled={field.IsNew == true ? false : true}
        options={section.ObjectFields}
        value={field.OptimiserFieldName ? { value: field.OptimiserFieldName, label: section.ObjectFields.find((f: any) => f.value == field.OptimiserFieldName).label } : ""}
        onChange={(e: any) => handleInputChange(sectionIndex, fieldIndex, 'OptimiserFieldName', e ? e.value : "")}
      />
      {section.validationMessages.FieldsMap[fieldIndex].OptimiserFieldName && (
        <div className="error">{section.validationMessages.FieldsMap[fieldIndex].OptimiserFieldName}</div>
      )}
    </div>

    {/* <div className={"icon-bx text-left" + " " + (isFormDisabled && "is-disabled")} style={{ marginTop: "32px" }}>
      <i className="fa fa-trash-alt trash-icon" onClick={() => handleDeleteField(sectionIndex, fieldIndex)}></i>
    </div> */}
    <div className={"update-icon-bx2 text-left d-flex d-flex-2" + " " + ((isFormDisabled || section.SelectedOption == "option2") && "is-disabled")} style={{ marginTop: "8px" }}>
      {/* <span>Update</span> */}
      <label className="switch" htmlFor={"checkbox" + sectionIndex+fieldIndex}>
        <input id={"checkbox" + sectionIndex+fieldIndex}  type="checkbox" checked={field.IsChecked} value={field.IsChecked || false} onChange={(e:any)=>handleSliderChange(sectionIndex,fieldIndex,e.target.checked)} />
        <span className="slider round"></span>
      </label>
      {/* <div>
        <a style={{ color: '#075da8', marginLeft: '4px' }} id={"fieldUpdateInfo" + sectionIndex + fieldIndex}><i className="fa fa-info-circle"></i></a>
        <Tooltip
          anchorSelect={"#fieldUpdateInfo" +  sectionIndex + fieldIndex}
          variant='light'
          border={'1px solid #075da8'}
          content="By turning this on, existing data in this field, in Optimiser, will be updated"
          place='right'
        />
      </div> */}
    </div>
    <div className={"update-icon-bx2 text-left d-flex d-flex-2" + " " + ((isFormDisabled || section.SelectedOption == "option2") && "is-disabled")} style={{ marginTop: "8px" }}>
      {/* <span>Update</span> */}
      <label className="switch" htmlFor={"prefillCheckbox" + sectionIndex+fieldIndex}>
        <input id={"prefillCheckbox" + sectionIndex+fieldIndex}  type="checkbox" checked={field.isPrefill} value={field.isPrefill || false} onChange={(e:any)=>handlePrefillChange(sectionIndex,fieldIndex,e.target.checked)} />
        <span className="slider round"></span>
      </label>
    </div>
    <div className="clear"></div>
  </div>
);

export default FieldsMap;
