import { useEffect, useState } from "react";
import moment from "moment-timezone";
import useAxios from "../../../hooks/useAxios";

const PanelFields = ({ d, inField}: any) => {
    console.log(inField)
    const axios = useAxios();
    //const [detailRecords, setDetailRecords] = useState([...recordDetail]);

    const formatDate = (dateString: any, isShowTime: any, field?: any) => {
        if (isShowTime) return moment(dateString).format("DD-MM-YYYY h:mm A");
        else return field.dateFormat ? moment(dateString).format(field.dateFormat) : moment(dateString).format("MM-DD-YYYY");
    };

    const downloadFile = async (file: any) => {
        let { data }: any = await axios.get("common/signed-download-url", {
            params: { path: "/data/files/"+file.name },
        });
        let link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("hidden", "true");
        link.setAttribute("download", "");
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    useEffect(() => {
        let ignorType = ["multilookupfield","lookupfield","file","panelDynamic","address", "image", "customlabel"];
        d.templateElements = d.templateElements.filter((te:any)=>!ignorType.includes(te.type) && te.inputType !="password");
        // recordDetail.forEach((d: any) => {
        //     console.log(d);
        //     const { field } = d;
        //     if (d.type == "address") cleanAddressFields(d, field);
        // });
        console.log(d.templateElements,"---");
    }, []);

    const cleanAddressFields = (d: any, field: any) => {
        if (field.includes(".country")) {
            if (typeof d[field] === "object") {
                d[field] = "";
            } else d[field] = d[field];
        } else if (field.includes(".city") || field.includes(".state") || field.includes(".postalCode")) {
            if (typeof d[field] === "object") {
                d[field] = "";
            } else d[field] = d[field];
        }
    };

    return (
        <>
            {
                d.templateElements.map((templateElement: any, i: any) => (
                <div className="">
                    <strong style={{fontSize:"12px", margin:"2px 0"}}>
                    {(() => {
                        const result = templateElement?.title?.trim() || templateElement?.name?.trim() || "";
                        return result ? `${result} : ` : "";
                    })()}
                    </strong>
                    {templateElement.inputType === "datetime-local" ? (
                       
                            <span>{inField[templateElement.valueName] ? moment.utc(inField[templateElement.valueName]).format("DD-MM-YYYY h:mm A") : ""}</span>
                        
                    ) : templateElement.inputType === "date" || templateElement.type === "datepicker" ? (
                        
                            <span>{inField[templateElement.valueName] ? formatDate(d[templateElement.field], false, d) : ""}</span>
                       
                    ) : templateElement.type === "boolean" || templateElement.type === "privacypolicy" || templateElement.type === "termsconditions" ? (
                      
                            <span>{inField[templateElement.valueName] ? "Yes" : "No"}</span>
                       
                    ) : templateElement.type === "multilookupfield" ? (
                      
                            <span>{inField[templateElement.valueName] ? inField[templateElement.valueName]?.join(", ") : ""}</span>
                       
                    ) : templateElement.type === "lookupfield" ? (
                            <span>{inField[templateElement.valueName] || ""}</span>
                       
                    ) : templateElement.type === "tagbox" || templateElement.type === "checkbox" ? (
                    
                            <span>{inField[templateElement.valueName] ? inField[templateElement.valueName].join(", ") : ""}</span>
                        
                    ) : templateElement.type === "file" ? (
                        <div>
                           {
                            inField[templateElement.valueName] && (
                                <div>{
                                    inField[templateElement.valueName].map((file: any, index: any) => (
                                        <div key={index} className="ml-auto multi-action-button">
                                            <span style={{ fontSize: "15px", color: "blue" }}>{file.name}</span>
                                            {/* <code style={{ fontSize: "15px", color: "#bb2124", marginLeft: "5px" }}>{(parseInt(file.Size) / 1024).toFixed(2)} MB</code> */}
                                            <i onClick={() => downloadFile(file)} className="btn btn-sm far fa-download download-file ml-10" data-toggle="tooltip" data-placement="top" title="Download" style={{ marginLeft: "10px" }} />
                                        </div>
                                    ))}
                                </div>
                            )
                        }
                        </div>
                    ) : templateElement.type === "fullname" ? (
                       
                            <span>
                                {inField[templateElement.valueName]?.prefixDropdown && templateElement.prefixVisible && templateElement.prefixFieldType !== "text" ? inField[templateElement.valueName]?.prefixDropdown : ""}
                                {" "}{inField[templateElement.valueName]?.prefixText && templateElement.prefixVisible && templateElement.prefixFieldType === "text" ? inField[templateElement.valueName]?.prefixText : ""}
                                {" "}{inField[templateElement.valueName]?.firstName ? inField[templateElement.valueName]?.firstName  : ""}
                                {" "} {inField[templateElement.valueName]?.middleName && templateElement.middleNameVisible ? inField[templateElement.valueName]?.middleName : ""}
                                {" "} {inField[templateElement.valueName]?.lastName || ""}
                                
                            </span>
                    ) : templateElement.type === "address" ? (
                        <span>
                                {inField[templateElement.valueName]?.streetAddressLine1 || ""},{" "} 
                                {inField[templateElement.valueName]?.streetAddressLine2 || ""}, {" "}
                                {inField[templateElement.valueName]?.city || ""},{" "}
                                {inField[templateElement.valueName]?.state || ""},{" "}
                                {inField[templateElement.valueName]?.country || ""},{" "}
                                {inField[templateElement.valueName]?.postalcode || ""}
                                
                        </span>
                        
                    ) : (
                         inField[templateElement.valueName] || ""
                        )
                    }
                </div>
            ))}

            <style>
                {`
            .font-weight-bold {
                font-weight: 700 !important;
            }
            
            `}
            </style>
        </>
    );
};

export default PanelFields;
