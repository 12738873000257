import React, { useState ,useEffect} from 'react';
import moment from "moment-timezone";
const NestedTable= ({ columns,data }:any) => {
    //console.log(thPosition)
   // console.log(columns)
   // console.log(data)
  // State for column widths

  const [dpHeight,setDepHeight] = useState(70);

  const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>(
    columns.reduce((acc:any, column:any) => {
      acc[column.valueName] = 100; // Default column width
      return acc;
    }, {} as { [key: string]: number })
  );

  useEffect(() => {
    if(data){
      let height = 60 * data.length; // Calculate the height based on data length
      // Apply constraints:
      // - If height > 300, set it to 300
      // - If height < 70, set it to 70
      // - Otherwise, keep the calculated height
      height = height > 300 ? 300 : height < 70 ? 70 : height;

      setDepHeight(height); // Set the height using the state setter
    }
    
  }, []);

  // State for sorting
  // const [sortConfig, setSortConfig] = useState<{ key:any; direction: 'asc' | 'desc' }>({
  //   key: null,
  //   direction: 'asc',
  // });

  // Handle column resizing
  const startResize = (valueName: any, e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    const startWidth = columnWidths[valueName];

    const onMouseMove = (e: MouseEvent) => {
      let newWidth = startWidth + (e.clientX - startX);
      if(newWidth <=100) newWidth = 100
      setColumnWidths((prev) => ({ ...prev, [valueName]: newWidth }));
    };

    const onMouseUp = () => {
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  // Handle column sorting
  // const requestSort = (key:any) => {
  //   let direction: 'asc' | 'desc' = 'asc';
  //   if (sortConfig.key === key && sortConfig.direction === 'asc') {
  //     direction = 'desc';
  //   }
  //   setSortConfig({ key, direction });
  // };

  // Sort data
  // const sortedData = useMemo(() => {
  //   if (!sortConfig.key) return data;

  //   return [...data].sort((a, b) => {
  //     if (a[sortConfig.key] < b[sortConfig.key]) {
  //       return sortConfig.direction === 'asc' ? -1 : 1;
  //     }
  //     if (a[sortConfig.key] > b[sortConfig.key]) {
  //       return sortConfig.direction === 'asc' ? 1 : -1;
  //     }
  //     return 0;
  //   });
  // }, [data, sortConfig]);

  // let positionFromRight = "auto";
  // let positionFromLeft = "auto";
  //   if (thPosition.positionFromLeft <= 1200) {
  //       // If there's enough space on the right, align to the left
  //       //positionFromLeft = `${thPosition.positionFromRight + window.scrollX}px`;
  //       positionFromLeft = "0px";
  //   }else {
  //       // If there's not enough space on either side, default to the left
  //       positionFromLeft = `${thPosition.positionFromLeft - 1200}px`;
  //   }
    const getColumnValue = (column: any, data: any) => {
      //console.log(column, "============",data)
      //return "fffffffff"
      //if(!data) return "";
      switch (column.type) {
        case "text":
          if (column.inputType === "date") {
            return data ? moment(data).format("DD/MM/YYYY") : "";
          } else if (column.inputType === "datetime-local") {
            return  data ? moment(data).format("DD/MM/YYYY hh:mm A") : "";
          } else {
            return data; // Covers all other `inputType` cases
          }
    
        case "datepicker":
          return column.dateFormat ? data ? moment(data).format(column.dateFormat) :"" : data ? moment(data).format("MM-DD-YYYY") :"";
    
        case "file":
          return data ? "Yes" : "No"; // Fixes syntax issue
    
        case "boolean":
        case "termsconditions":
        case "privacypolicy":
          return data === true ? "Yes" : "No";
    
        case "comment":
          return data && data.length > 50 ? data.substr(0, 50) + "..." : data;
    
        case "dropdown":
        case "lookupfield":
        case "monthfield":
          return data ? data :"";
    
        case "checkbox":
        case "tagbox":
        case "multilookupfield": {
          if (data && data.length) {
            return data.join(", "); // Simplified loop
          }
          return "";
        }
    
        case "fullname":
          if (data) {
            let fullName = "";
            fullName += data.prefixDropdown && column.prefixVisible && column.prefixFieldType !== "text" ? data.prefixDropdown + " " : "";
            fullName += data.prefixText && column.prefixVisible && column.prefixFieldType === "text" ? data.prefixText + " " : "";
            fullName += data.firstName ? data.firstName + " " : "";
            fullName += data.middleName && column.middleNameVisible ? data.middleName + " " : "";
            fullName += data.lastName ? data.lastName + " " : "";
            return fullName.trim();
          }
          return data;
    
        default:
          return data;
      }
    };
    
  return (
    <div style={{ position: 'relative',width: '1200px' }}>
    <div
      style={{
        //position: 'absolute',
       //top: '0px', // Adjust as needed
        //left: 0,
        //right: positionFromRight, // Adjust as needed
        width: '1200px', // Adjust as needed
        maxHeight: dpHeight+"px", // Adjust as needed
        overflow: 'auto',
        //zIndex: 1000, // Ensure it's above other content
      }}
    >
      <table
      className="nested-table"
        style={{
          width: 'max-content',
          minWidth: '100%',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            {columns.map((column:any) => (
              <th
                draggable={false}
                key={column.valueName}
                style={{
                  width: columnWidths[column.valueName],
                  padding: '5px',
                  borderBottom: '1px solid #ddd',
                  position: 'relative',
                  cursor: 'pointer',
                 minWidth:'100px'
                 
                }}
                //onClick={() => requestSort(column.valueName)}
              >
                {column.title}
                {/* {sortConfig.key === column.valueName && (
                  <span>{sortConfig.direction === 'asc' ? (<i className="myIcon fas fa-sort "></i>) : (<i className="myIcon fas fa-sort "></i>)}</span>
                )} */}
               
                <div className="grip" draggable={false}onMouseDown={(e) => startResize(column.valueName, e)}/>
              </th>
            ))}
            <th style={{display:"none"}}></th>
          </tr>
        </thead>
        <tbody>
        {data && data.length > 0 ? (
              data.map((row: any, rowIndex: number) => (
                <tr key={rowIndex} className="innernestedtr">
                  {columns.map((column: any) => (
                    <td
                      key={column.valueName}
                      style={{
                        width: columnWidths[column.valueName],
                        padding: '5px',
                        borderBottom: '1px solid #ddd',
                          minWidth:'100px'
                      }}
                    >
                      {getColumnValue(column, row[column.valueName])}
                    </td>
                    
                  ))}
                  <td style={{display:"none"}}></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={columns.length} style={{ textAlign: "center", padding: "5px", fontWeight: "bold" }}>
                  No records found
                </td>
              </tr>
            )}

        </tbody>
      </table>
    </div>
    </div>
  );
};

export default NestedTable;