import React from "react";
import Select from "react-select";
import { Tooltip } from 'react-tooltip';

const FieldsMap = ({ field, items, sectionIndex, fieldIndex, section, handleUniqueFieldsChange, isFormDisabled, getPreviousSectionIds, handleDeleteUniqueField, filteredObjectUniqueFields }: any) => (
    <div className="form-sec">
        <div className="field-bx form-group">
            {/* <label>Form Field</label> */}
            <Select
                isClearable={true}
                isDisabled={field.IsNew == true ? false : isFormDisabled}
                options={items.filter((item: any) => section.FieldsMap.some((field: any) => field.FormFieldName == item.name)).concat(getPreviousSectionIds(sectionIndex))}
                value={field.FormFieldName ? { value: field.FormFieldName, label: items.find((f: any) => f.name == field.FormFieldName) ? items.find((f: any) => f.name == field.FormFieldName).label : field.FormFieldName } : ""}
                onChange={(e: any) => {const selectedItem = items.find((item: any) => item.name === e?.value); 
                    handleUniqueFieldsChange(sectionIndex, fieldIndex, "FormFieldName", e ? e.value : "", selectedItem?.type )
                }}
            />
            {section.validationMessages.UniqueFields[fieldIndex].FormFieldName && (
                <div className="error">{section.validationMessages.UniqueFields[fieldIndex].FormFieldName}</div>
            )}
        </div>
        <div className="Optimiser-bx form-group">
            <Select
                isClearable={true}
                // isDisabled={field.IsNew == true ? false : isFormDisabled}
                isDisabled={true}
                // options={filteredObjectUniqueFields[section.ObjectName]}
                value={field.OptimiserFieldName ? { value: field.OptimiserFieldName, label: section.ObjectFields.find((f: any) => f.value == field.OptimiserFieldName)?.label } : ""}
                // onChange={(e: any) => handleUniqueFieldsChange(sectionIndex, fieldIndex, "OptimiserFieldName", e ? e.value : "")}
            />
            {section.validationMessages.UniqueFields[fieldIndex].OptimiserFieldName && (
                <div className="error">{section.validationMessages.UniqueFields[fieldIndex].OptimiserFieldName}</div>
            )}
        </div>
        <div className={"d-flex d-flex-2 icon-bx text-left" + (isFormDisabled ? " is-disabled" : "")}>

            { (!field.IsRequired || field.OptimiserFieldName === "OwnerID" ||  typeof field.DefaultValue === "boolean" || field.DefaultValue === null || !section.ApplyFormValidation) && (
                // <div className={"icon-bx text-left" + (isFormDisabled ? " is-disabled" : "")} style={{ marginTop: "32px" }}>
                    <i className="fa fa-trash-alt trash-icon" onClick={() => handleDeleteUniqueField(sectionIndex, fieldIndex)}></i>
                // </div>
            )} 

            { (fieldIndex == 0) && ( 
                <div style={{ marginLeft: '4px'}}>
                    <a style={{ color: '#075da8', marginLeft: '4px' }} id={"fieldSecurityInfo" + sectionIndex + fieldIndex}><i className="fa fa-info-circle"></i></a>
                    <Tooltip
                    anchorSelect={"#fieldSecurityInfo" +  sectionIndex + fieldIndex}
                    variant='light'
                    border={'1px solid #075da8'}
                    content="To increase security of your form, we recommend setting more than 1 field."
                    place='right'
                    />
                </div> 
            )}
         
        </div>
        <div className="clear"></div>
    </div>
);

export default FieldsMap;